import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { countryInfo, ITimeZoneInfo, IUser, IUserPrimaryAddress, TokenStatus } from './auth.models';

export interface AuthState extends EntityState<IUser> {
    isLoggedIn: boolean;
    isLoading: boolean;
    error: string | null;
    email: string,
    password: string,
    accessTokenStatus: TokenStatus;
    refreshTokenStatus: TokenStatus;
    isLoadingLogin: boolean;
    hasLoginError: boolean;
    errorList: { [key: string]: string },
    countryInfo: countryInfo[],
    timeZone: ITimeZoneInfo[],
    primaryAddress: IUserPrimaryAddress | null,
    verificationId: string;
}

export const userAdapter = createEntityAdapter<IUser>();

export const initialAuthState: AuthState = userAdapter.getInitialState({
    isLoggedIn: false,
    isLoading: false,
    error: null,
    email: '',
    password: '',
    accessTokenStatus: TokenStatus.PENDING,
    refreshTokenStatus: TokenStatus.PENDING,
    isLoadingLogin: false,
    hasLoginError: false,
    errorList: {},
    countryInfo: [],
    timeZone: [],
    primaryAddress: null,
    verificationId: ''
});
