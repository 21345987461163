import { IAPIResponse, IAPIResponseHeaders, IAPIrequestBase } from "../../../models/base";

export interface IUserPrimaryAddress {
    id: string;
    accountId: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    countryCode: string;
    isPrimary: boolean;
    createdAt: Date;
    updatedAt: Date;
    createdBy: string;
    updatedBy: string;
}
export interface IUser {
    id: string;
    accountId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNo: string;
    isEmailVerified: boolean;
    isPhoneNoVerified: boolean;
    userType: string;
    userTimezone: string;
    isActive: boolean;
    isOnboarded: boolean;
    signedupVia: string;
    createdAt: Date;
    updatedAt: Date;
    emailVerifiedAt: Date;
    onBoardingCompletedAt: Date;
    fullName: string;
}

export interface IEmailLoginRequest extends IAPIrequestBase {
    email: string;
    password: string;
}

export interface IAuthCredentials {
    email: string;
    password: string;
}

export interface ILogoutRequest {
    refreshKey: string;
}

export interface IEmailSignupRequest extends IAPIrequestBase {
    email: string;
    password: string;
    confirmPassword: string
}

export interface IVerifyEmailRequest extends IAPIrequestBase {
    email: string,
    otp: string
}

export interface IResendEmailVerificationRequest extends IAPIrequestBase {
    email: string,
}

export interface IVerifyEmailResponse {
    headers: IAPIResponseHeaders;
}

export interface IVerifyPhoneNoRequest {
    otp: string,
    verificationId: string
}

export interface IVerifyPhoneNoResponse {
    headers: IAPIResponseHeaders;
}

export interface IEmailSignupResponse {
    headers: IAPIResponseHeaders;
    isVerificationRequired: boolean
}

export interface IUserSubAccountLogin {
    username: string;
    password: string;
}

export enum TokenStatus {
    PENDING = 'PENDING',
    VALIDATING = 'VALIDATING',
    VALID = 'VALID',
    INVALID = 'INVALID',
}

export interface ILoginAPIResponse extends IAPIResponse<undefined> {
    accessToken: string;
    refreshKey: string;
    isVerificationRequired: boolean;
    expiresIn: number;
}

export interface ILoginAPIErrorResponse extends IAPIResponse<undefined> {
    headers: IAPIResponseHeaders;
    isVerificationRequired: boolean
}

export interface IUserAPIResponse extends IAPIResponse<undefined> { 
    userProfile: IUser,
    userPrimaryAddress: IUserPrimaryAddress
}

export interface IProfileUpdateAPIResponse { 
    headers: IAPIResponseHeaders,
    phoneVerificationId: string,
    phonenoVerificationRequired: boolean
}

export interface IUserProfileAPIRequest { 
    firstName: string,
    lastName: string,
    phoneNo: string,
    address: string,
    city: string,
    state: string,
    countryCode: string,
    zipcode: string
    timezone: string,
    middleName: string,
    phoneCode: string,
}

export interface countryInfo {
    country: string
    countryCode: string
    currencyCode: string
    phoneCode: string
}

export interface IMasterCountryResponse {
    headers: IAPIResponseHeaders,
    data: countryInfo[]
}

export interface ITimeZoneInfo {
    gmtOffset: string
    timeZone: string
}

export interface IMasterTimeZoneResponse {
    headers: IAPIResponseHeaders,
    data: ITimeZoneInfo[]
}