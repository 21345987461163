// auth.facade.ts

import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { selectCurrentUser, selectAuthError, selectIsLoggedIn, selectAuthErrorList, selectIsLoadingLogin, selectRegisteredEmail, selectIsUserOnboarded, selectCountryOptions, selectTimezoneOptions, selectPrimaryAddress, selectMobileVerificationId, selectIsLoading, } from './auth.selectors'; // Adjust the import path based on your actual file structure
import { IEmailLoginRequest, ILogoutRequest, IEmailSignupRequest, IVerifyEmailRequest, IResendEmailVerificationRequest, IUserProfileAPIRequest, IVerifyPhoneNoRequest } from './auth.models';

@Injectable({
    providedIn: 'root',
})
export class AuthFacade {
    private readonly store: Store = inject(Store);

    isLoggedIn$ = this.store.select(selectIsLoggedIn);
    isOnboarded$ = this.store.select(selectIsUserOnboarded);
    currentUser$ = this.store.select(selectCurrentUser);
    userAddress$ = this.store.select(selectPrimaryAddress);
    loginLoading$ = this.store.select(selectIsLoadingLogin);
    isLoading$ = this.store.select(selectIsLoading);
    authError$ = this.store.select(selectAuthError);
    errorList$ = this.store.select(selectAuthErrorList);
    currentRegisteredUser$ = this.store.select(selectRegisteredEmail);
    countryInfo$ = this.store.select(selectCountryOptions);
    timeZones$ = this.store.select(selectTimezoneOptions);
    mobileVerificationId$ = this.store.select(selectMobileVerificationId)


    login(credentials: IEmailLoginRequest): void {
        this.store.dispatch(AuthActions.loginRequest({ credentials }));
    }

    signUp(credentials: IEmailSignupRequest): void {
        this.store.dispatch(AuthActions.signupRequest({ credentials }));
    }

    verifyEmail(credentials: IVerifyEmailRequest): void {
        this.store.dispatch(AuthActions.verifyEmailRequest({ credentials }));
    }

    resendVerificationEmail(credentials: IResendEmailVerificationRequest): void {
        this.store.dispatch(AuthActions.resendVerifyEmailRequest({ credentials }));
    }

    verifyPhoneNo(credentials: IVerifyPhoneNoRequest): void {
        this.store.dispatch(AuthActions.verifyPhoneNoRequest({ credentials }));
    }
    
    resendOTP(): void {
        this.store.dispatch(AuthActions.resendOTPRequest());
    }

    logout(credentials: ILogoutRequest): void {
        this.store.dispatch(AuthActions.logout({ credentials }));
    }

    // Additional facade methods can be added based on your requirements
    loadTokenFromStorage(): void {
        this.store.dispatch(AuthActions.loadTokenFromStorage());
    }

    loadAuthUser(): void {
        this.store.dispatch(AuthActions.getAuthUserRequest());
    }

    loadCountries(): void {
        this.store.dispatch(AuthActions.loadCountryRequest())
    }

    loadTimeZones(): void {
        this.store.dispatch(AuthActions.loadTimeZoneRequest())
    }

    updateProfile(credentials: IUserProfileAPIRequest): void {
        this.store.dispatch(AuthActions.updateProfileRequest({ credentials }));
    }
    
    onboardUser(): void{
        this.store.dispatch(AuthActions.onboardUserRequest());
    }

}
