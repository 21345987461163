import { AppState } from '../../store/index';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthState, userAdapter } from './auth.state';
import { IUser } from './auth.models';

export const selectAuthFeature: MemoizedSelector<AppState, AuthState> =
    createFeatureSelector<AuthState>('auth');

// Use memoized selectors from @ngrx/entity
export const {
    selectAll
} = userAdapter.getSelectors();

// Create selectors to extract specific slices of state
export const selectIsLoggedIn = createSelector(
    selectAuthFeature,
    (state) => state.isLoggedIn
);

export const selectAuthError = createSelector(
    selectAuthFeature,
    (state) => state.error
);

export const selectIsLoading = createSelector(
    selectAuthFeature,
    state => state.isLoading
);

export const selectAuthErrorList = createSelector(
    selectAuthFeature,
    state => state.errorList
);

export const selectCurrentUser = createSelector(
    selectAuthFeature,
    (state) => state.ids.length > 0 ? state.entities[state.ids[0]] : null
)

export const selectPrimaryAddress = createSelector(
    selectAuthFeature,
    (state) => state.ids.length > 0 ? state.primaryAddress : null
)

export const selectIsUserOnboarded = createSelector(
    selectCurrentUser,
    (currentUser: IUser | null | undefined) => {
        return currentUser ? currentUser?.isOnboarded : null
    }
);

export const selectIsLoadingLogin = createSelector(
    selectAuthFeature,
    state => state.isLoadingLogin
);

export const selectRegisteredEmail = createSelector(
    selectAuthFeature,
    state => state?.email
);

export const selectAuthPassword = createSelector(
    selectAuthFeature,
    state => state?.password
);
export const selectCountryOptions = createSelector(
    selectAuthFeature,
    state => state?.countryInfo
);

export const selectTimezoneOptions = createSelector(
    selectAuthFeature,
    state => state?.timeZone
);

export const selectMobileVerificationId = createSelector(
    selectAuthFeature,
    state => state?.verificationId
);